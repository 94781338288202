<template>
	<!-- 牛市分类 -->
	<div class="bullMarket-category">
		<div class="el-content mb12">
			<a-space>
				<a-input-search style="width: 400px;"
					v-model:value="search.key" 
					placeholder="请输入查询关键字" 
					enter-button 
					@search="getBullCategoryList(1,info.limit)" />
				<a-button type="primary" @click="toEditCategory(0)">添加分类</a-button>
			</a-space>
		</div>
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'名称',dataIndex:'name',slots:{customRender:'name'}},
				{title:'排序',dataIndex:'sort'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #name="{record}">
					<div style="display: flex;align-items: center;">
						<a-avatar size="large" :src="record.icon"/>
						<span style="margin-left: 8px;">{{record.name}}</span>
					</div>
				</template>
				<template #action="{record}">
					<a-space>
						<kd-button type="primary" title="编辑" icon="ri-edit-line" @click="toEditCategory(record)"></kd-button>
						<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" @click="delCategory([record.id])"></kd-button>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getBullCategoryList(info.page,e)}"
					@change="(e)=>{getBullCategoryList(e,info.limit)}"
				/>
			</div>
		</div>
		<a-modal v-model:visible="show.add" title="新增/编辑分类" @ok="saveCategory" width="600px" @cancel="show.add = false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="名称">
					<a-input placeholder="请输入分类名称" v-model:value="form.name"></a-input>
				</a-form-item>
				<a-form-item label="图标">
					<kd-img-select :src="form.icon" @success="(url)=>{ form.icon = url }"></kd-img-select>
				</a-form-item>
				<a-form-item label="排序">
					<a-input placeholder="排序" v-model:value="form.sort"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import bullModel from '@/api/addons/bull'
export default{
	setup(){
		const state = reactive({
			search:{
				key:''
			},
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			show:{
				add:false,
			},
			form:null,
		})
		getBullCategoryList(1,state.info.limit)
		function getBullCategoryList(page,limit){
			bullModel.getBullCategory(page,limit,state.search,res=>state.info = {limit,...res})
		}

		function toEditCategory(row){
			state.form = {
				id: row ? row.id:0,
				name: row ? row.name:"",
				icon: row ? row.icon:"",
				sort: row ? row.sort:"",
				status:row ? row.status:1,
			}
			state.show.add = true
		}

		const saveCategory = ()=>bullModel.addOrEditCate(state.form,()=>{
			getBullCategoryList(state.info.page,state.info.limit)
			state.show.add = false
		})

		const delCategory = id=>bullModel.deleteBullCate(id,()=>getBullCategoryList(state.info.page,state.info.limit))

		return{
			...toRefs(state),
			getBullCategoryList,
			saveCategory,
			toEditCategory,
			delCategory
		}
	}
}
</script>

<style lang="scss">
</style>
